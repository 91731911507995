import React, { Component } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import HomeBoard from "./components/boards/HomeBoard";
import ErrorBoard from "./components/boards/ErrorBoard";

import ReporteList from "./components/reportes/ReporteList";
import ReporteAdd from "./components/reportes/ReporteAdd";
import ReporteView from "./components/reportes/ReporteView";
import ReporteInforme from "./components/reportes/ReporteInforme";

import HidABCDEFAdd from "./components/reportes/1hidrologicos/add/HidABCDEFAdd";
import HidGHIAdd from "./components/reportes/1hidrologicos/add/HidGHIAdd";
import HidJKLAdd from "./components/reportes/1hidrologicos/add/HidJKLAdd";
import HidABCDEF from "./components/reportes/1hidrologicos/edit/HidABCDEF";
import HidGHI from "./components/reportes/1hidrologicos/edit/HidGHI";
import HidJKL from "./components/reportes/1hidrologicos/edit/HidJKL";

import GeoABCDEAdd from "./components/reportes/2geologicos/add/GeoABCDEAdd";
import GeoFAdd from "./components/reportes/2geologicos/add/GeoFAdd";
import GeoABCDE from "./components/reportes/2geologicos/edit/GeoABCDE";
import GeoF from "./components/reportes/2geologicos/edit/GeoF";

import EcoABAdd from "./components/reportes/3ecologicos/add/EcoABAdd";
import EcoAB from "./components/reportes/3ecologicos/edit/EcoAB";

import OrgABCAdd from "./components/reportes/4organizativos/add/OrgABCAdd";
import OrgDEFGHIAdd from "./components/reportes/4organizativos/add/OrgDEFGHIAdd";
import OrgJKLMNOAdd from "./components/reportes/4organizativos/add/OrgJKLMNOAdd";
import OrgPAdd from "./components/reportes/4organizativos/add/OrgPAdd";
import OrgABC from "./components/reportes/4organizativos/edit/OrgABC";
import OrgDEFGHI from "./components/reportes/4organizativos/edit/OrgDEFGHI";
import OrgJKLMNO from "./components/reportes/4organizativos/edit/OrgJKLMNO";
import OrgP from "./components/reportes/4organizativos/edit/OrgP";

import QuiABCAdd from "./components/reportes/5quimicos/add/QuiABCAdd";
import QuiDEFGHIJKAdd from "./components/reportes/5quimicos/add/QuiDEFGHIJKLAdd";
import QuiABC from "./components/reportes/5quimicos/edit/QuiABC";
import QuiDEFGHIJKL from "./components/reportes/5quimicos/edit/QuiDEFGHIJKL";

import PerAAdd from "./components/reportes/6personalizado/add/PerAAdd";
import PerA from "./components/reportes/6personalizado/edit/PerA";

import AstABCAdd from "./components/reportes/7astronomicos/add/AstABCAdd";
import AstABC from "./components/reportes/7astronomicos/edit/AstABC";

import EditUser from "./components/users/EditUser";
import LoginUser from "./components/users/LoginUser";
import RegisterUser from "./components/users/RegisterUser";
import UserList from "./components/users/UserList";

import Refugio from "./components/refugios/Refugio";
import RefugioList from "./components/refugios/RefugioList";
import RefugioAdd from "./components/refugios/RefugioAdd";
import RefugioView from "./components/refugios/RefugioView";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginUser />} />
          <Route path="/user/:id" element={<EditUser />} />
          <Route path="/register" element={<RegisterUser />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/home" element={<HomeBoard />} />
          <Route path="/error" element={<ErrorBoard />} />
          <Route path="/reportesList" element={<ReporteList />} />
          <Route path="/list/:tipo" element={<ReporteList />} />
          <Route path="/reporte/:id" element={<ReporteView />} />
          <Route path="/informe" element={<ReporteInforme />} />
          <Route path="/add" element={<ReporteAdd />} />
          <Route path="/addHidA/:tipo" element={<HidABCDEFAdd />} />
          <Route path="/addHidG/:tipo" element={<HidGHIAdd />} />
          <Route path="/addHidJ/:tipo" element={<HidJKLAdd />} />
          <Route path="/HidA/:id" element={<HidABCDEF />} />
          <Route path="/HidG/:id" element={<HidGHI />} />
          <Route path="/HidJ/:id" element={<HidJKL />} />
          <Route path="/addGeoA/:tipo" element={<GeoABCDEAdd />} />
          <Route path="/addGeoF/:tipo" element={<GeoFAdd />} />
          <Route path="/GeoA/:id" element={<GeoABCDE />} />
          <Route path="/GeoF/:id" element={<GeoF />} />
          <Route path="/addEcoA/:tipo" element={<EcoABAdd />} />
          <Route path="/EcoA/:id" element={<EcoAB />} />
          <Route path="/addOrgA/:tipo" element={<OrgABCAdd />} />
          <Route path="/addOrgD/:tipo" element={<OrgDEFGHIAdd />} />
          <Route path="/addOrgJ/:tipo" element={<OrgJKLMNOAdd />} />
          <Route path="/addOrgP/:tipo" element={<OrgPAdd />} />
          <Route path="/OrgA/:id" element={<OrgABC />} />
          <Route path="/OrgD/:id" element={<OrgDEFGHI />} />
          <Route path="/OrgJ/:id" element={<OrgJKLMNO />} />
          <Route path="/OrgP/:id" element={<OrgP />} />
          <Route path="/addQuiA/:tipo" element={<QuiABCAdd />} />
          <Route path="/addQuiD/:tipo" element={<QuiDEFGHIJKAdd />} />
          <Route path="/QuiA/:id" element={<QuiABC />} />
          <Route path="/QuiD/:id" element={<QuiDEFGHIJKL />} />
          <Route path="/addPerA/:tipo" element={<PerAAdd />} />
          <Route path="/PerA/:id" element={<PerA />} />
          <Route path="/addAstA/:tipo" element={<AstABCAdd />} />
          <Route path="/AstA/:id" element={<AstABC />} />
          <Route path="/addRefugio" element={<RefugioAdd />} />
          <Route path="/refugiosList" element={<RefugioList />} />
          <Route path="/refugios/:id" element={<Refugio />} />
          <Route path="/refugio/:id" element={<RefugioView />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
